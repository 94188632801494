import React, { useEffect, useState } from "react";

import Events from "./Program/Events";
import Filter, { FilterState, filterEvents } from "./Program/Filter";

interface State {
  loaded: boolean;
  events: Event[];
}

interface Props {
  url: string;
}

export default function Program(props: Props) {
  const [state, setState] = useState<State>({ loaded: false, events: [] });
  const { loaded, events } = state;

  const [filterState, setFilterState] = useState<FilterState>({
    venue: null,
    month: null,
    search: ""
  });

  const updateFilterState = (nextState: FilterState) => {
    setFilterState({ ...filterState, ...nextState });
  };

  useEffect(() => {
    void fetch(props.url)
      .then((response) => response.json())
      .then((data) => setState({ loaded: true, events: data as Event[] }));
  }, []);

  return (
    <div className="program">
      {loaded && (
        <>
          <Filter
            events={events}
            filterState={filterState}
            updateFilterState={updateFilterState}
          />
          <Events events={filterEvents(events, filterState)} />
        </>
      )}
    </div>
  );
}
